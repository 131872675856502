<template>
  <app-layout-main class="px-4">
    <div
      class="
        container
        px-4
        m-auto
        mx-auto
        md:px-10
        max-w-lg
        space-y-8
        bg-contain bg-app-logo bg-no-repeat bg-center
      "
    >
      <app-logo-cryptopay :class="['w-full h-14']"/>

      <app-card>
        <h3 class="mb-10 font-semibold text-md text-center">
          {{ $t("auth.login.reset_password") }}
        </h3>

        <form @submit.prevent="resetPassword" class="space-y-5">
          <app-form-password-input
            :labelText="$t('general.new_password')"
            name="password"
            type="password"
            v-model="user.password"
            v-on:passwordValidated="onPasswordValidated"
            :errorMessage="errors.password"
            :passwordstrength="true"
            :showError="showPasswordError || !passwordValidated"
          />

          <app-form-password-input
            :labelText="$t('general.retype_password')"
            type="password"
            name="confirm_password"
            :errorMessage="errors.confirm_password"
            v-model="user.confirm_password"
          />

          <app-alert-error :message="errorMessage" />

          <app-button type="submit" :loading="loading">
            {{ $t("general.change_password") }}
          </app-button>
        </form>
      </app-card>
    </div>
  </app-layout-main>
</template>


<script>
export default {
  name: "reset-password-page",

  data() {
    return {
      emptyErrors: {
        password: [],
        confirm_password: [],
      },
    };
  },

  computed: {
    user() {
      return this.$store.getters["authStore/user"];
    },

    loading() {
      return this.$store.getters["authStore/loading"];
    },

    errors() {
      return this.$store.getters["authStore/errors"] ?? this.emptyErrors;
    },

    message() {
      return this.$store.getters["authStore/message"];
    },

    passwordValidated() {
      return this.$store.getters["authStore/passwordValidated"];
    },
  },

  methods: {
    onPasswordValidated(validated) {
      this.$store.commit("authStore/setPasswordValidated", validated);
    },

    async resetPassword() {
      this.resetState();
      const resetPasswordResult = await this.$store.dispatch(
        "authStore/resetPassword", {
          user: this.user,
          token: this.$route.query.token
        }
      );

      if (resetPasswordResult == null) {
        return;
      }

      this.$router.push({ name: "success-reset-password" });
    },

    resetState() {
      this.$store.commit("authStore/resetState");
    },
  },
};
</script>